$black: #0A0A14;
$portfolio-black: #1C1B18;
$blue: #1330E2;
$gray: #868686;
$workduration-black: #000A19;
$border-gray: #D8D8D8;
$grey: #EAEAEA;
$light-green: #CDEFEA;
$separate-gray: #CCCED1;

$dm-separator-gray: #4E505D;

$dm-bgcolor: #000a19;