.sp-hero-bg {
    padding-top: 12rem;
    padding-bottom: 6rem;

    h1 {
        font-family: PlayfairDisplay-SemiBold;
        font-size: 64px;
        color: $black;
        letter-spacing: -1px;
        line-height: 90px;
        margin-bottom: 5rem;
    }

    p {
        font-family: Calibre-Medium;
        font-size: 40px;
        color: $black;
        letter-spacing: -1px;
        line-height: 60px;
    }
}

.full-section-bg {
    // background-color: $light-green;
    // background-size: cover;
    // height: 850px;

    img {
        width: 100%;
    }
}

.portfolio-border-bottom {
    border-bottom: 1px solid $grey;
}

.text-section-padd {
    padding-top: 5rem;

    h5 {
        font-family: Calibre-Medium;
        font-size: 24px;
        color: $workduration-black;
        letter-spacing: -0.5px;
        line-height: 36px;
        margin-top: 3rem;
        text-transform: uppercase;
    }

    h2 {
        font-family: Calibre-Medium;
        font-size: 50px;
        color: $workduration-black;
        letter-spacing: -0.5px;
        line-height: 64px;
        margin-top: 1.5rem;
    }

    h6 {
        font-family: Calibre-Medium;
        font-size: 24px;
        color: $workduration-black;
        letter-spacing: 0;
        line-height: 36px;
        margin-top: 3rem;
        text-transform: uppercase;
    }

    img {
        width: 100%;
    }

    .app-image-deco {
        border-radius: 55px;
        box-shadow: 4px 2px 32px 0px rgba(0, 45, 39, 0.08);
    }

    .work-duration-para {
        font-family: Calibre-Medium;
        font-size: 20px;
        color: $workduration-black;
        letter-spacing: 0;
        line-height: 30px;
        margin-top: .2rem;
        margin-bottom: 0;
    }

    .problem-statement-para {
        border-left: 3px solid $border-gray;
        font-family: Calibre-Medium;
        font-size: 32px;
        color: $workduration-black;
        letter-spacing: 0;
        line-height: 48px;
        margin-top: 2rem;
        margin-bottom: 0;
        padding-left: 1rem;
    }

    .main-text-para {
        font-family: Calibre-Regular;
        font-size: 20px;
        color: $workduration-black;
        letter-spacing: 0;
        line-height: 26px;
        margin-top: 2rem;
        margin-bottom: 0;
    }

    .recognition-div-link {
        color: $blue;
    }

    hr {
        border-top: 1px solid $workduration-black;
    }

    .recognition-div {
        margin-top: 5rem;
    }

    .portfolio-image-div {
        margin-top: 10rem;
    }

    .breakdown-segment-div {

        .breakdown-segment-img {
            min-height: 50px;

            img {
                width: 15%;
            }
        }

        .breakdown-segment-title {

            h4 {
                font-family: Calibre-Medium;
                font-size: 20px;
                color: #000A19;
                letter-spacing: 0;
                line-height: 30px;
            }
        }

        .breakdown-segment-text {

            p {
                font-family: Calibre-Regular;
                font-size: 18px;
                color: #000A19;
                letter-spacing: 0;
                line-height: 27px;
            }
        }
    }

    .separator-hr-div {
        // margin-top: 8rem;

        hr {
            border-top: 1px solid $separate-gray;
            margin-top: 0;
        }
    }
}

.more-projects-padd {
    padding-top: 5rem;
    padding-bottom: 5rem;

    h3 {
        font-family: Calibre-Medium;
        font-size: 24px;
        color: $workduration-black;
        letter-spacing: 0;
        text-align: center;
        line-height: 36px;
    }

    img {
        width: 100%;
    }
}

.dark-mode-toggle {

    .sp-hero-bg {
        padding-top: 12rem;
        padding-bottom: 10rem;
    
        h1 {
            font-family: PlayfairDisplay-SemiBold;
            font-size: 64px;
            color: #fff;
            letter-spacing: -1px;
            line-height: 90px;
            margin-bottom: 5rem;
        }
    
        p {
            font-family: Calibre-Medium;
            font-size: 40px;
            color: #fff;
            letter-spacing: -1px;
            line-height: 60px;
        }
    }
    
    .full-section-bg {
        // background-color: $light-green;
        // background-size: cover;
        // height: 850px;
    
        img {
            width: 100%;
        }
    }
    
    .portfolio-border-bottom {
        border-bottom: 1px solid $dm-separator-gray;
    }
    
    .text-section-padd {
        padding-top: 5rem;
    
        h5 {
            font-family: Calibre-Medium;
            font-size: 24px;
            color: #fff;
            letter-spacing: -0.5px;
            line-height: 36px;
            margin-top: 3rem;
            text-transform: uppercase;
        }
    
        h2 {
            font-family: Calibre-Medium;
            font-size: 50px;
            color: #fff;
            letter-spacing: -0.5px;
            line-height: 64px;
            margin-top: 1.5rem;
        }
    
        h6 {
            font-family: Calibre-Medium;
            font-size: 24px;
            color: #fff;
            letter-spacing: 0;
            line-height: 36px;
            margin-top: 3rem;
            text-transform: uppercase;
        }
    
        img {
            width: 100%;
        }
    
        .work-duration-para {
            font-family: Calibre-Medium;
            font-size: 20px;
            color: #fff;
            letter-spacing: 0;
            line-height: 30px;
            margin-top: .2rem;
            margin-bottom: 0;
        }
    
        .problem-statement-para {
            border-left: 3px solid $border-gray;
            font-family: Calibre-Medium;
            font-size: 32px;
            color: #fff;
            letter-spacing: 0;
            line-height: 48px;
            margin-top: 2rem;
            margin-bottom: 0;
            padding-left: 1rem;
        }
    
        .main-text-para {
            font-family: Calibre-Regular;
            font-size: 20px;
            color: #fff;
            letter-spacing: 0;
            line-height: 26px;
            margin-top: 2rem;
            margin-bottom: 0;
        }

        .recognition-div-link {
            color: $blue;
        }

        hr {
            border-top: 1px solid $dm-separator-gray;
        }
    
        .recognition-div {
            margin-top: 5rem;
        }

        .portfolio-image-div {
            margin-top: 10rem;
        }

        .breakdown-segment-div {
    
            .breakdown-segment-img {
                min-height: 50px;
    
                img {
                    filter: invert(97%) sepia(97%) saturate(11%) hue-rotate(203deg) brightness(102%) contrast(105%);
                    width: 15%;
                }
            }
    
            .breakdown-segment-title {
    
                h4 {
                    font-family: Calibre-Medium;
                    font-size: 20px;
                    color: #fff;
                    letter-spacing: 0;
                    line-height: 30px;
                }
            }
    
            .breakdown-segment-text {
    
                p {
                    font-family: Calibre-Regular;
                    font-size: 18px;
                    color: #fff;
                    letter-spacing: 0;
                    line-height: 27px;
                }
            }
        }
    
        .separator-hr-div {
            // margin-top: 8rem;

            hr {
                border-top: 1px solid $dm-separator-gray;
                margin-top: 0;
            }
        }
    }
    
    .more-projects-padd {
        padding-top: 5rem;
        padding-bottom: 5rem;
    
        h3 {
            font-family: Calibre-Medium;
            font-size: 24px;
            color: #fff;
            letter-spacing: 0;
            text-align: center;
            line-height: 36px;
        }
    
        img {
            width: 100%;
        }
    }
}