.footer-bg {
  padding-top: 5rem;
  padding-bottom: 5rem;

  h4 {
    font-family: Calibre-Medium;
    font-size: 24px;
    color: $black;
    letter-spacing: -1px;
    line-height: 36px;
  }

  p {
    font-family: Calibre-Regular;
    font-size: 24px;
    color: $black;
    letter-spacing: -0.5px;
    line-height: 36px;
    margin-bottom: 2rem;
  }

  .social-divs {
    background: #132fe225;
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    padding: .8rem 2rem;
    margin-right: 1rem;

    img {
      margin-right: .5rem;
    }

    p {
      font-family: Calibre-Medium;
      font-size: 16px;
      display: inline-flex;
      color: #1330E2;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  .created-paragraph {
    opacity: 0.6;
    font-family: Calibre-Regular;
    font-size: 20px;
    color: $black;
    letter-spacing: -0.5px;
    line-height: 24px;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
}

.dark-mode-toggle {

  .footer-bg {
    padding-top: 5rem;
    padding-bottom: 5rem;
  
    h4 {
      font-family: Calibre-Medium;
      font-size: 24px;
      color: #fff;
      letter-spacing: -1px;
      line-height: 36px;
    }
  
    p {
      font-family: Calibre-Regular;
      font-size: 24px;
      color: #fff;
      letter-spacing: -0.5px;
      line-height: 36px;
      margin-bottom: 2rem;
    }
  
    .social-divs {
      background: #132fe225;
      border-radius: 50px;
      cursor: pointer;
      display: inline-block;
      padding: .8rem 2rem;
      margin-right: 1rem;
  
      img {
        margin-right: .5rem;
      }
  
      p {
        font-family: Calibre-Medium;
        font-size: 16px;
        display: inline-flex;
        color: #1330E2;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  
    .created-paragraph {
      opacity: 0.6;
      font-family: Calibre-Regular;
      font-size: 20px;
      color: #fff;
      letter-spacing: -0.5px;
      line-height: 24px;
      margin-top: 4rem;
      margin-bottom: 1rem;
    }
  }
}