@font-face {
  font-family: PlayfairDisplay-Black;
  src: url(../fonts/PlayfairDisplay-Black.ttf);
}

@font-face {
  font-family: PlayfairDisplay-BlackItalic;
  src: url(../fonts/PlayfairDisplay-BlackItalic.ttf);
}

@font-face {
  font-family: PlayfairDisplay-Bold;
  src: url(../fonts/PlayfairDisplay-Bold.ttf);
}

@font-face {
  font-family: PlayfairDisplay-BoldItalic;
  src: url(../fonts/PlayfairDisplay-BoldItalic.ttf);
}

@font-face {
  font-family: PlayfairDisplay-ExtraBold;
  src: url(../fonts/PlayfairDisplay-ExtraBold.ttf);
}

@font-face {
  font-family: PlayfairDisplay-ExtraBoldItalic;
  src: url(../fonts/PlayfairDisplay-ExtraBoldItalic.ttf);
}

@font-face {
  font-family: PlayfairDisplay-Italic;
  src: url(../fonts/PlayfairDisplay-Italic.ttf);
}

@font-face {
  font-family: PlayfairDisplay-Medium;
  src: url(../fonts/PlayfairDisplay-Medium.ttf);
}

@font-face {
  font-family: PlayfairDisplay-MediumItalic;
  src: url(../fonts/PlayfairDisplay-MediumItalic.ttf);
}

@font-face {
  font-family: PlayfairDisplay-Regular;
  src: url(../fonts/PlayfairDisplay-Regular.ttf);
}

@font-face {
  font-family: PlayfairDisplay-SemiBold;
  src: url(../fonts/PlayfairDisplay-SemiBold.ttf);
}

@font-face {
  font-family: PlayfairDisplay-SemiBoldItalic;
  src: url(../fonts/PlayfairDisplay-SemiBoldItalic.ttf);
}


@font-face {
  font-family: Calibre-Black;
  src: url(../fonts/Calibre-Black.otf);
}

@font-face {
  font-family: Calibre-BlackItalic;
  src: url(../fonts/Calibre-BlackItalic.otf);
}

@font-face {
  font-family: Calibre-Bold;
  src: url(../fonts/Calibre-Bold.otf);
}

@font-face {
  font-family: Calibre-BoldItalic;
  src: url(../fonts/Calibre-BoldItalic.otf);
}

@font-face {
  font-family: Calibre-Light;
  src: url(../fonts/Calibre-Light.otf);
}

@font-face {
  font-family: Calibre-LightItalic;
  src: url(../fonts/Calibre-LightItalic.otf);
}

@font-face {
  font-family: Calibre-Medium;
  src: url(../fonts/Calibre-Medium.otf);
}

@font-face {
  font-family: Calibre-MediumItalic;
  src: url(../fonts/Calibre-MediumItalic.otf);
}

@font-face {
  font-family: Calibre-Regular;
  src: url(../fonts/Calibre-Regular.otf);
}

@font-face {
  font-family: Calibre-RegularItalic;
  src: url(../fonts/Calibre-RegularItalic.otf);
}

@font-face {
  font-family: Calibre-Semibold;
  src: url(../fonts/Calibre-Semibold.otf);
}

@font-face {
  font-family: Calibre-SemiboldItalic;
  src: url(../fonts/Calibre-SemiboldItalic.otf);
}

@font-face {
  font-family: Calibre-Thin;
  src: url(../fonts/Calibre-Thin.otf);
}

@font-face {
  font-family: Calibre-ThinItalic;
  src: url(../fonts/Calibre-ThinItalic.otf);
}