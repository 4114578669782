.reset-padding {
  padding-left: 0;
  padding-right: 0;
}

.hero-bg {
  padding-top: 12rem;
  padding-bottom: 6rem;

  h1 {
    font-family: PlayfairDisplay-SemiBold;
    font-size: 64px;
    color: $black;
    letter-spacing: -1px;
    line-height: 80px;

    .grey-color {
      color: $gray;
    }

    .blue-color {
      color: $blue;
    }
  }

  p {
    font-family: Calibre-Regular;
    font-size: 24px;
    color: $black;
    letter-spacing: -0.5px;
    line-height: 36px;

    .blue-color {
      color: $blue;
    }
  }
}

.works-div {
  padding-top: 5rem;

  img {
    border-radius: 16px;
    cursor: pointer;
    width: 100%;
  }
}

.hr-div {

  hr {
    border: 1px solid $grey;
  }
}

.brands-div {
  padding-top: 5rem;
  padding-bottom: 5rem;

  h4 {
    font-family: Calibre-Medium;
    font-size: 24px;
    color: $black;
    letter-spacing: -1px;
    line-height: 36px;
  }

  p {
    font-family: Calibre-Regular;
    font-size: 24px;
    color: $black;
    letter-spacing: -0.5px;
    line-height: 36px;
    margin-bottom: 2rem;
  }

  img {
    text-align: center;
    cursor: pointer;
    width: 100%;
  }
}

.dark-mode-toggle {
  background-color: $dm-bgcolor;

  .hero-bg {
    padding-top: 12rem;
    padding-bottom: 6rem;
  
    h1 {
      font-family: PlayfairDisplay-SemiBold;
      font-size: 64px;
      color: #fff;
      letter-spacing: -1px;
      line-height: 80px;
  
      .grey-color {
        color: $gray;
      }
  
      .blue-color {
        color: $blue;
      }
    }
  
    p {
      font-family: Calibre-Regular;
      font-size: 24px;
      color: #fff;
      letter-spacing: -0.5px;
      line-height: 36px;
  
      .blue-color {
        color: $blue;
      }
    }
  }
  
  .works-div {
    padding-top: 5rem;
  
    img {
      width: 100%;
    }
  }
  
  .hr-div {
  
    hr {
      border: 1px solid $dm-separator-gray;
    }
  }

  .brands-div {
    padding-top: 5rem;
    padding-bottom: 5rem;
  
    h4 {
      font-family: Calibre-Medium;
      font-size: 24px;
      color: #fff;
      letter-spacing: -1px;
      line-height: 36px;
    }
  
    p {
      font-family: Calibre-Regular;
      font-size: 24px;
      color: #fff;
      letter-spacing: -0.5px;
      line-height: 36px;
      margin-bottom: 2rem;
    }
  
    img {
      text-align: center;
      cursor: pointer;
      width: 100%;
    }
  }
}