.navbar {
  background-color: #fff;
  padding: 2rem 1rem;
}

.navbar-dark .navbar-brand {

  img {
    filter: invert(5%) sepia(18%) saturate(1355%) hue-rotate(201deg) brightness(91%) contrast(101%);
  }
}

.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
  color: $blue;
  font-family: Calibre-Semibold;
  font-size: 20px;
}

.navbar-dark .navbar-nav .nav-link {
  color: $black;
  font-family: Calibre-Semibold;
  margin-right: 2rem;
  font-size: 20px;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: $blue;
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: $black;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url('../img/menuiconb.svg');
}

.dark-mode-toggle {
  .navbar {
    background-color: $dm-bgcolor;
    padding: 2rem 1rem;
  }

  .navbar-dark .navbar-brand {
  
    img {
      filter: invert(99%) sepia(1%) saturate(524%) hue-rotate(184deg) brightness(117%) contrast(100%);
    }
  }

  .navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
    color: #fff;
    font-family: Calibre-Semibold;
    font-size: 20px;
  }
  
  .navbar-dark .navbar-nav .nav-link {
    color: #fff;
    font-family: Calibre-Semibold;
    margin-right: 2rem;
    font-size: 20px;
  }
  
  .navbar-dark .navbar-nav .nav-link:hover {
    color: $blue;
  }

  .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff;
  }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url('../img/menuiconb.svg');
  }
}
